<template>
  <form class="k-form" id="signupForm" v-on:submit.prevent="onSubmit()">
    <p class="tcenter">고객님의 비밀번호가 성공적으로 재설정 되었습니다.
      <img src="../../../assets/img/etners-main/img_finish.png" class="mtb25"/></p>
    <fieldset>
      <input type="submit" value="로그인 하기" class="mid_btn orange login_btn" style="cursor: pointer;">
    </fieldset>
  </form>
</template>

<script>
  export default {
    name: 'findPasswordComplete',
    data () {
      return {
        userId: null,
        userPwd: null,
        solCd: null,
      }
    },
    props: [
      'method',
    ],
    methods: {
      onSubmit () {
        this.$router.push({ name: 'Login' })
      },
    },
  }

</script>
